// Contentful change.
// - New data source
// - New query for Contentful

import React from 'react';
import { Link, graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';

const CategoriesListTemplate = ({ data }) => {
  const {
    title,
    subtitle
  } = data.site.siteMetadata;

  // This looks like it will need to change for Contenful data.allContentfulBlogPost
  // and then 
  // const { group } = data.allMarkdownRemark;
  const { group } = data.allContentfulBlogPost;

  // This looks fine for Contentful
  return (
    <Layout title={`Categories - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="Categories">
        <ul>
          {group.map((category) => (
            <li key={category.fieldValue}>
              <Link to={`/category/${kebabCase(category.fieldValue)}/`}>
                {category.fieldValue} ({category.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Page>
    </Layout>
  );
};

// export const query = graphql`
//   query CategoriesListQuery {
//     site {
//       siteMetadata {
//         title
//         subtitle
//       }
//     }
//     allMarkdownRemark(
//       filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
//     ) {
//       group(field: frontmatter___category) {
//         fieldValue
//         totalCount
//       }
//     }
//   }
// `;

export const query = graphql`
  query CategoriesListQuery {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allContentfulBlogPost {
			group(field: category) {
        fieldValue
        totalCount
    }
  }
}
`;

export default CategoriesListTemplate;
